<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ item.id !== null ? "Modifier status" : "Nouveau statut" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.label"
                    type="text"
                    label="Libellé"
                    :rules="[rules.label]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.code"
                    label="Code"
                    type="text"
                    :rules="[rules.code]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-row class="justify-content-center">
                    <v-color-picker
                      v-model="item.color"
                      mode="hexa"
                      :rules="[rules.color]"
                      width="450"
                    >
                    </v-color-picker>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, watch,
} from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'StatusRecFormDialog',

  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const rules = {
      label: true,
      code: true,
      color: true,
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      rules.label = true
      rules.code = true
      rules.color = true
      instance.emit('clickout')
    }

    watch(() => props.dialog, dialog => {
      // eslint-disable-next-line no-unused-expressions
      dialog || close()
    })

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      console.log(item.value)
      if (item.value.id !== null) {
        store.dispatch('statusRec/updateStatusRec', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.color = error.response.data.errors.color !== undefined ? error.response.data.errors.color[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('statusRec/addStatusRec', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.color = error.response.data.errors.color !== undefined ? error.response.data.errors.color[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    return {
      close,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      item,
      color,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
