<template>
  <div>
    <custom-toolbar
      :title="$t('Recovery_status')"
      :total="$store.getters['statusRec/total']"
      :add="'Nouveau status de recouvrement'"
      @refresh="getStatusRec"
      @add="openForm"
    />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.statusRec.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['statusRec/statuses_rec']"
        :items-per-page="$store.getters['statusRec/per_page']"
        :page.sync="$store.getters['statusRec/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.color`]="{item}">
          <v-chip
            :color="item.color"
            small
          >
            {{ item.color }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <template #[`item.deleted_at`]="{item}">
          <v-chip
            v-show="item.deleted_at !== null"
            color="error"
            small
          >
            <span class="font-weight-bold">{{ item.deleted_at ? formattingDate(`${item.deleted_at}`) : null }}</span>
          </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :lock="false"
            :view="false"
            :status="false"
            @edit="openForm(item)"
            @remove="openItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.recovery.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getStatusRecPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getStatusRec"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <status-rec-form-dialog
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
      @submitted="loading"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import {
  getCurrentInstance, onMounted, ref, watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { formatDate } from '@core/date/dateFunctions'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiRefresh,
  mdiSquareEditOutline, mdiDeleteRestore, mdiPlus,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import StatusRecFormDialog from '@core/components/statusRec/StatusRecFormDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import store from '@/store'

export default {
  name: 'StatusRec',
  components: {
    ButtonsGroup, CustomToolbar, ConfirmDialog, StatusRecFormDialog, LoaderDialog,
  },

  setup() {
    const instance = getCurrentInstance()
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const item = ref({})
    const date = ref(null)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const menu = ref(false)
    const messageDialog = ref('')
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const { router } = useRouter()

    const save = d => {
      menuref.value.save(d)
    }

    const getStatusRec = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('statusRec/getStatusesRecList', { page, field: store.state.statusRec.field }).then(() => {
        currentPage.value = store.getters['statusRec/current_page']
        lastPage.value = store.getters['statusRec/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const refreshStatusRec = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('statusRec/refreshStatusesRecList', { page, field: store.state.statusRec.field }).then(() => {
        currentPage.value = store.getters['statusRec/current_page']
        lastPage.value = store.getters['statusRec/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    watch(() => store.state.user.lang, lang => {
      refreshStatusRec(store.getters['statusRec/current_page'])
    })

    const closeDialog = () => {
      dialog.value = false
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const filterItems = () => {
      setTimeout(() => {
        getStatusRec()
      }, 1500)
    }

    const getStatusRecPerPage = per_page => {
      isDialogVisible.value = true
      store.state.statusRec.field.paginate = per_page
      store.dispatch('statusRec/getStatusesRecList', { field: store.state.statusRec.field }).then(() => {
        currentPage.value = store.getters['statusRec/current_page']
        lastPage.value = store.getters['statusRec/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const deleteItem = () => {
      isDialogVisible.value = true
      if (item.value.deleted_at === null) {
        store.dispatch('statusRec/deleteStatusRec', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      } else {
        store.dispatch('statusRec/restoreStatusRec', item.value).then(response => {
          isDialogVisible.value = false
          isAlertVisible.value = false
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          isAlertVisible.value = true
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      }
    }

    const openForm = val => {
      if (val) item.value = val
      else {
        item.value = {
          id: null, label: null, code: null, color: null, color_object: null,
        }
      }
      dialog.value = true
    }

    const formattingDate = date => formatDate(date)

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const deleteItemConfirm = () => {
      this.closeDelete()
    }

    const openItem = object => {
      messageDialog.value = object.deleted_at === null ? `Voulez-vous supprimer le statut ${object.label}?` : `Voulez-vous restaurer le statut ${object.label}?`
      item.value = object
      dialogDelete.value = true
    }

    onMounted(() => {
      getStatusRec(store.getters['statusRec/current_page'])
    })

    return {
      deleteItem,
      dialog,
      item,
      messageDialog,
      loading,
      closeDialog,
      openForm,
      filterItems,
      openItem,
      getStatusRecPerPage,
      deleteItemConfirm,
      closeDelete,
      save,
      getStatusRec,
      formattingDate,
      currentPage,
      message,
      lastPage,
      isAlertVisible,
      isDialogVisible,
      dialogDelete,
      error,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'LABEL', value: 'label' },
        { text: 'CODE', value: 'code' },
        { text: 'COULEUR', value: 'color' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiDeleteRestore,
        mdiPlus,
      },
    }
  },
}
</script>

<style scoped>

</style>
